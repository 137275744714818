/* Document Ready
 * -------------------------------
 * */
$(document).ready(function() {
    /* Slick Slider
     * -------------------------------
     * */
    $('.slider--team').not('.slick-initialized').slick({
       //infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        prevArrow: '<i class="fa fa-chevron-left slide-prev" aria-hidden="true"></i>',
        nextArrow: '<i class="fa fa-chevron-right slide-next" aria-hidden="true"></i>',
      //fade: true,
     cssEase: 'linear',
     speed: 700,
     // adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                    touchThreshold:100,
                    waitForAnimate:false,
                    speed: 500,
                    touchMove: true,
                    swipe: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            }

        ]
    }).on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $('.slick-slide').removeClass('slick-current-before');
        $(slick.$slides[currentSlide]).addClass('slick-current-before');
    });
});







