import './scss/app.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import * as dat from 'lil-gui'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import $ from "jquery";
import { Tooltip, Toast, Popover } from 'bootstrap';
import 'slick-carousel'
window.$ = window.jQuery = $;


import {} from './app';
import gsap from 'gsap'

import portalVertexShader from './shaders/portal/vertex.glsl'
import portalFragmentShader from './shaders/portal/fragment.glsl'
import firefliesVertexShader from './shaders/fireflies/vertex.glsl'
import firefliesFragmentShader from './shaders/fireflies/fragment.glsl'
import overlayVertexShader from './shaders/overlay/vertex.glsl'
import overlayFragmentShader from './shaders/overlay/fragment.glsl'
/**
 * Base
 */
// Debug

/*
const debugObject = {}

const gui = new dat.GUI({
    width: 400
})

debugObject.portalColorStart = '#d328b5'
debugObject.portalColorEnd = '#0c1433'

gui
    .addColor(debugObject, 'portalColorStart')
    .onChange(() =>
    {
        LabLiquid1.uniforms.uColorStart.value.set(debugObject.portalColorStart)
    })

gui
    .addColor(debugObject, 'portalColorEnd')
    .onChange(() =>
    {
        LabLiquid1.uniforms.uColorEnd.value.set(debugObject.portalColorEnd)
    })


 */




// Canvas
const canvas = document.querySelector('canvas.webgl')

if(canvas){

const portalLightMaterial1 = new THREE.ShaderMaterial({
    vertexShader: portalVertexShader,
    fragmentShader: portalFragmentShader,
    uniforms:
        {
            uTime: { value: 0 },
            uColorStart: { value: new THREE.Color(0xd328b5) },
            uColorEnd: { value: new THREE.Color(0x0c1433) }
        }
})



const portalLightMaterial2= new THREE.ShaderMaterial({
    vertexShader: portalVertexShader,
    fragmentShader: portalFragmentShader,
    uniforms:
        {
            uTime: { value: 0 },
            uColorStart: { value: new THREE.Color(0x1b8186) },
            uColorEnd: { value: new THREE.Color(0x0c1433) }
        }
})

const portalLightMaterial3= new THREE.ShaderMaterial({
    vertexShader: portalVertexShader,
    fragmentShader: portalFragmentShader,
    uniforms:
        {
            uTime: { value: 0 },
            uColorStart: { value: new THREE.Color(0x86d241) },
            uColorEnd: { value: new THREE.Color(0x0c321a) }
        }
})

const portalLightMaterial4= new THREE.ShaderMaterial({
    vertexShader: portalVertexShader,
    fragmentShader: portalFragmentShader,
    uniforms:
        {
            uTime: { value: 0 },
            uColorStart: { value: new THREE.Color(0xd25b28) },
            uColorEnd: { value: new THREE.Color(0x32110c) }
        }
})


const LabLiquid1 = new THREE.ShaderMaterial({
    vertexShader: portalVertexShader,
    fragmentShader: portalFragmentShader,
    uniforms:
        {
            uTime: { value: 0 },
            uColorStart: { value: new THREE.Color(0xf0ff2) },
            uColorEnd: { value: new THREE.Color(0xfbae47) }
        }
})

const LabLiquid2 = new THREE.ShaderMaterial({
    vertexShader: portalVertexShader,
    fragmentShader: portalFragmentShader,
    uniforms:
        {
            uTime: { value: 0 },
            uColorStart: { value: new THREE.Color(0x45e2fa) },
            uColorEnd: { value: new THREE.Color(0xfe3bff) }
        }
})

const LabLiquid3 = new THREE.ShaderMaterial({
    vertexShader: portalVertexShader,
    fragmentShader: portalFragmentShader,
    uniforms:
        {
            uTime: { value: 0 },
            uColorStart: { value: new THREE.Color(0x46fb49) },
            uColorEnd: { value: new THREE.Color(0x46fb49) }
        }
})


/**
 * Fireflies
 */
// Geometry
const firefliesGeometry = new THREE.BufferGeometry()
const firefliesCount = 30
const positionArray = new Float32Array(firefliesCount * 3)
const scaleArray = new Float32Array(firefliesCount)
for(let i = 0; i < firefliesCount; i++)
{
    positionArray[i * 3 + 0] = -1.04
    positionArray[i * 3 + 1] = Math.random()
    positionArray[i * 3 + 2] = -0.92
    scaleArray[i] = Math.random()
}

firefliesGeometry.setAttribute('position', new THREE.BufferAttribute(positionArray, 3))
firefliesGeometry.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1))
// Material
const firefliesMaterial = new THREE.ShaderMaterial({
    transparent: true,
    blending: THREE.AdditiveBlending,
    uniforms:
        {
            uTime: { value: 0 },
            uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
            uSize: { value: 100 }
        },
    vertexShader: firefliesVertexShader,
    fragmentShader: firefliesFragmentShader
})



// Points

const fireflies = new THREE.Points(firefliesGeometry, firefliesMaterial)










// Scene
const scene = new THREE.Scene()


/**
 * Overlay
 */
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    uniforms:
        {
            uAlpha: { value: 1 }
        },
    transparent: true,
    vertexShader: overlayVertexShader,
    fragmentShader: overlayFragmentShader
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)

/**
 * Loaders
 */
let sceneReady = false
const textureLoader = new THREE.TextureLoader()
const cubeTextureLoader = new THREE.CubeTextureLoader()
const environmentMap = cubeTextureLoader.load([
    '/wp-content/themes/Gelatoversenft-theme/dist/textures/environmentMaps/9/SSKY_01_05_left.png',
    '/wp-content/themes/Gelatoversenft-theme/dist/textures/environmentMaps/9/SSKY_01_05_right.png',
    '/wp-content/themes/Gelatoversenft-theme/dist/textures/environmentMaps/9/SSKY_01_05_up.png',
    '/wp-content/themes/Gelatoversenft-theme/dist/textures/environmentMaps/9/SSKY_01_05_down.png',
    '/wp-content/themes/Gelatoversenft-theme/dist/textures/environmentMaps/9/SSKY_01_05_front.png',
    '/wp-content/themes/Gelatoversenft-theme/dist/textures/environmentMaps/9/SSKY_01_05_back.png'
])
const loadingBarElement = document.querySelector('.loading-bar')

const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        window.setTimeout(() =>
        {
            if(loadingBarElement){
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 1, value: 0, delay: 1 })
            loadingBarElement.classList.add('ended')
            loadingBarElement.style.transform = ''
            sceneReady = true
            }
        }, 500)
    },
    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        if(loadingBarElement){
        const progressRatio = itemsLoaded / itemsTotal
        loadingBarElement.style.transform = `scaleX(${progressRatio})`
        }
    }
)

//environmentMap.encoding = THREE.sRGBEncoding
// Draco loader
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/wp-content/themes/Gelatoversenft-theme/dist/draco/')
// GLTF loader
const gltfLoader = new GLTFLoader(loadingManager)
gltfLoader.setDRACOLoader(dracoLoader)

/**
 * grid helper
 */

//const axesHelper = new THREE.AxesHelper( 10 );
//scene.add( axesHelper );
/**
 * Texture
 */
const bakedTexture = textureLoader.load('/wp-content/themes/Gelatoversenft-theme/dist/cityF.jpg')
bakedTexture.flipY= false;
//bakedTexture.encoding = THREE.sRGBEncoding

/**
 * Material
 */

const bakedMaterial = new THREE.MeshBasicMaterial({
    map: bakedTexture,
    side: THREE.DoubleSide,

})
bakedMaterial.map.minFilter = THREE.LinearFilter;
const directionalLight = new THREE.DirectionalLight('#ffffff', 1)
directionalLight.position.set(0.25, 3, - 2.25)
const light = new THREE.AmbientLight( 0x02000f , 6); // soft white light
const video = document.createElement( 'video' );
video.src = "/wp-content/themes/Gelatoversenft-theme/dist/Video/Gelatoverse_ZombiesChasing.mp4";
video.width = 240
video.height = 240
video.muted = true;
video.loop = true;
video.load();


/**
 * Music
 */
var firstClick = false;
var audio = new Audio('/wp-content/themes/Gelatoversenft-theme/dist/Music/SpaceAmbience.mp3');
audio.playbackRate=0.4;
audio.volume = 0.1;
audio.loop= true;

var sectionPlay = document.getElementById('section--hero');
var mute = document.getElementById('btn-mute');
if(sectionPlay){
sectionPlay.addEventListener('click', () =>
{
    document.getElementById("openmenu").checked = false;
    if ( firstClick == false ) {
        audio.play();
        video.play();
        firstClick = true
        mute.getElementsByTagName('i')[0].classList.remove("fa-volume-off");
        mute.getElementsByTagName('i')[0].classList.add("fa-volume-up");
    }
})
mute.addEventListener('click', (e) =>
{
    if ( audio.paused == false) {
        audio.pause();
        audio.currentTime = 0;
        mute.getElementsByTagName('i')[0].classList.remove("fa-volume-up");
        mute.getElementsByTagName('i')[0].classList.add("fa-volume-off");
    }
    else{
        audio.play();
        mute.getElementsByTagName('i')[0].classList.remove("fa-volume-off");
        mute.getElementsByTagName('i')[0].classList.add("fa-volume-up");
    }
})
}

const matcapTextureVideo = new THREE.VideoTexture( video );
const videoMaterial = new THREE.MeshBasicMaterial({
    map: matcapTextureVideo,
   side: THREE.FrontSide,
   toneMapped: false
})

/**
 * Model
 */

let mixer

gltfLoader.load(
    '/wp-content/themes/Gelatoversenft-theme/dist/cityF.glb',
    (gltf)=>
{

    gltf.scene.traverse((child)=>
    {
       //child.material = bakedMaterial
        if ( child.name ==='LabLiquid' ) {
            child.material = LabLiquid1  ;
        }
        if ( child.name ==='Lab_Liquid' ) {
            child.material = LabLiquid2  ;
        }

        if ( child.name ==='LabLiquid001' ) {
            child.material = LabLiquid3  ;
        }

       if ( child.name ==='Arcade_screen' ) {
            child.material = videoMaterial ;
        }

        if ( child.name ==='Portal_1001_Emission') {
            child.material = portalLightMaterial1
        }
        if ( child.name ==='Portal_1_Emission') {
            child.material = portalLightMaterial2
        }
        if ( child.name ==='Portal_1003_Emission') {
            child.material = portalLightMaterial3
        }
        if ( child.name ==='Portal_1002_Emission') {
            child.material = portalLightMaterial4
        }

        if ( child.name ==='OctoGround_Top') {
            child.material = bakedMaterial
        }
        if ( child.name ==='Curb') {
            child.material = bakedMaterial
        }

    })

    scene.add(gltf.scene)
    scene.add(fireflies)
    mixer = new THREE.AnimationMixer( gltf.scene );
    gltf.animations.forEach( ( clip ) => {
        mixer.clipAction( clip ).play();
    });
}
)
scene.add(directionalLight)
scene.add( light );
scene.background = environmentMap
/**
 * Fonts
 */


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    // Update fireflies
    firefliesMaterial.uniforms.uPixelRatio.value = Math.min(window.devicePixelRatio, 2)
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.x = -3
camera.position.y = 2
camera.position.z = 3
scene.add(camera)

// Controls
if(canvas){
    var controls = new OrbitControls(camera, canvas)
    controls.enabled = true
    controls.zoomSpeed = 1
    controls.rotateSpeed = 1
    controls.minDistance = 0
    controls.maxDistance = 4.5
    controls.minPolarAngle = 0.5
    controls.maxPolarAngle = 1.5
    controls.autoRotate = true
    controls.autoRotateSpeed = 0.5
}




/**
 * Points of interest
 */
const raycaster = new THREE.Raycaster()
const points = [
    {
        position: new THREE.Vector3(-0.8, 1.2,-1.3),
        element: document.querySelector('.point-0'),

    },
    {
        position: new THREE.Vector3(0, 1.3,1.14),
        element: document.querySelector('.point-1')

    },
    {
        position: new THREE.Vector3(0, 1,-0.01),
        element: document.querySelector('.point-2')

    },
    {
        position: new THREE.Vector3(0, 1.2,-0.5),
        element: document.querySelector('.point-3')

    },
    {
        position: new THREE.Vector3(0.5, 1.2,-1.3),
        element: document.querySelector('.point-4')

    }
]



/**
 * Animation
 */

function updateCameraTweens(params) {
    function target(callback){
            gsap.to( controls.target, {
                duration: 3,
                x: params.target.x,
                y: params.target.y,
                z: params.target.z, // maybe adding even more offset depending on your model
                onUpdate: function() {

                }
            } );
            callback();
        }

    function Position(){
        gsap.to( camera.position, {
            duration: 3,
            x: params.position.x,
            y: params.position.y,
            z: params.position.z, // maybe adding even more offset depending on your model
            onUpdate: function() {

            }
        } );
    }
    target(Position)
}
var ModalStatus = false
function showModal(id){
    if(id == "story"){
        video.play();
    }
    controls.autoRotate = false
    controls.enabled = false
    closeAll()
    var pointwraps = document.querySelectorAll(".point-wrap");
    for(const pointwrap  of pointwraps ){
        pointwrap.style.display="none"
    }

    ModalStatus = true
    window.scrollTo(0,1);
    document.getElementById(id).classList.add("active");
    document.getElementById(id).classList.remove("hide");
    const close = document.getElementById(id).querySelector('.point-close')
    if(close){
        close.addEventListener('click', function(ev) {
            closeModal();
        });
    }
}
function closeModal(){
    closeAll()
    ModalStatus = false
    controls.autoRotate = true
    controls.enabled = true

    var resetCameraSettings = {
            position: {x: controls.position0.x, y: controls.position0.y, z: controls.position0.z},
            target: {x: controls.target0.x , y: controls.target0.y , z: controls.target0.z}
    };

    updateCameraTweens(resetCameraSettings)

//controls.reset ()
}


function closeAll(){
    var sections = document.querySelectorAll(" .section:not(.section--hero)");
    for(const section  of sections ){
        section.classList.add("hide");
        section.classList.remove("active");
    }
    var pointwraps = document.querySelectorAll(".point-wrap");
    for(const pointwrap  of pointwraps ){
        pointwrap.style.display="block"
    }

}


var buttonCameraSettings = {
   tabs: {
       position: {x: -0.387, y: 0.202, z: -0.056},
       target: {x: -0.172, y: 0.175, z: -0.416}
   },
    story: {
        position: {x: -1.259, y: 0.444, z: 1.080},
        target: {x: 0.663, y: 0.099, z: 0.959}
    },
    roadmap: {
        position: {x: -1.586, y: 0.905, z: -0.634},
        target: {x: 0.213, y: 0.473, z: 0.278}
    },
    team: {
        position: {x: -0.854, y: 1.417, z: 1.864},
        target: {x: -0.805, y: 0.461, z: -0.212}
    },
    faq: {
        position: {x: 2.465, y: 1.500, z: -0.808},
        target: {x: -0.335, y: 0.152, z: -0.677}
    }
};

//var page = '{{ page }}';

var navLinks = document.getElementsByClassName('nav-home');
if(navLinks ){
    for(const navLink  of navLinks ) {
        navLink.addEventListener('click', function(ev) {
            ev.preventDefault();
            controls.saveState();
            var pointhref = ev.target.href;
            const pointId = pointhref.substring(pointhref.indexOf('#') + 1)
            var cameraSettings = buttonCameraSettings[pointId];
            document.getElementById("openmenu").checked = false;
            // document.querySelector('.offcanvas-end').classList.remove("show");
            //   document.querySelector('.offcanvas-backdrop').classList.remove("show");
            // document.body.removeAttribute('style');
            // document.header.removeAttribute('style');
            updateCameraTweens(cameraSettings);

            showModal(pointId);
        });
    }
}








const checkbox = document.getElementById("openmenu")
checkbox.addEventListener('change', (event) => {

    if (event.currentTarget.checked) {

        if (ModalStatus){
        closeModal()
           // console.log("close");
    } }
})

for(const point of points)
{

        point.element.addEventListener('click', function(ev) {
            ev.preventDefault();
            controls.saveState ();
            var pointhref = ev.target.href;
            const pointId = pointhref.substring(pointhref.indexOf('#') + 1)
            var cameraSettings = buttonCameraSettings[pointId];
            updateCameraTweens(cameraSettings);
            showModal(pointId);
        });
        point.element.addEventListener('mouseover', function(ev) {
            var audioButton = new Audio('/wp-content/themes/Gelatoversenft-theme/dist/Music/buttonUI.mp3');
            //audioButton.play();
        });
    }

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
   antialias: true,
    alpha:true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

//renderer.shadowMap.enabled = true;
//renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap


//renderer.outputEncoding = THREE.sRGBEncoding


/*
debugObject.clearColor = '#ff0000'
renderer.setClearColor(0x000000, 0)
gui
    .addColor(debugObject, 'clearColor')
    .onChange(() =>
    {
        renderer.setClearColor(debugObject.clearColor)
    })
 */




/**
 * Animate
 */
const clock = new THREE.Clock();
var display = 'block';
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
        display = 'none';
    }
const tick = () =>
{

    const deltaTime = clock.getDelta()
    const elapsedTime = clock.getElapsedTime()
    //console.log(camera.position)
    //console.log(controls.target)
    // Update materials
    portalLightMaterial1.uniforms.uTime.value = elapsedTime * 2
    portalLightMaterial2.uniforms.uTime.value = elapsedTime * 2
    portalLightMaterial3.uniforms.uTime.value = elapsedTime * 2
   portalLightMaterial4.uniforms.uTime.value = elapsedTime * 2
    LabLiquid1.uniforms.uTime.value = elapsedTime
   LabLiquid2.uniforms.uTime.value = elapsedTime * 2
    LabLiquid3.uniforms.uTime.value = elapsedTime * 4
    firefliesMaterial.uniforms.uTime.value = elapsedTime
    if(sceneReady)
    {

    for(const point of points)
    {
        const screenPosition = point.position.clone()
        screenPosition.project(camera)
        raycaster.setFromCamera(screenPosition, camera)
        const intersects = raycaster.intersectObjects(scene.children, true)
        const translateX = screenPosition.x * sizes.width * 0.5
        const translateY = - screenPosition.y * sizes.height * 0.5
        point.element.style.display = display
        point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
        
    }
    }

    if ( mixer ) mixer.update( deltaTime * 5);
    matcapTextureVideo.needsUpdate = true
    controls.update();
    renderer.render(scene, camera)
    window.requestAnimationFrame(tick)
}

tick()

}